import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { getUserVATCodes } from '../../common/user/UserActions';
import { getCurrentUser, selectUserActiveVatCodes } from '../../common/user/UserSelectors';
import { selectCurrentCompanySettings, selectIsCurrentCompanySettingsLoading } from '../../common/company/CompanySelectors';
import { GlobalState } from '../../rootReducer';

import AutoTransactionsAddView, { DispatchProps, Props } from './AutoTransactionsAddView';
import { addOrUpdateAT, getCompanyVatRates, retrieveAT } from './AutoTransactionsAddViewActions';
import { selectCompanyVatRates, selectInitialValues, selectRetrieveATLoadable } from './AutoTransactionsAddViewReducer';

const mapStateToProps = (state: GlobalState): Props => {
    return {
        currentCompanySettingsLoading: selectIsCurrentCompanySettingsLoading(state),
        currentCompanySettings: selectCurrentCompanySettings(state),
        retrieveATLoadable: selectRetrieveATLoadable(state),
        initialValues: selectInitialValues(state),
        currentUser: getCurrentUser(state),
        userActiveVatCodes: selectUserActiveVatCodes(state),
        companyVatRates: selectCompanyVatRates(state),
    };
};

const mapDispatchToProps: DispatchProps = {
    addOrUpdateAT,
    retrieveAT,
    getUserVATCodes,
    getCompanyVatRates,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AutoTransactionsAddView));
